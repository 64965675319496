import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { path, prop } from 'ramda';
import Heading from '@greenberry/salal/components/Heading';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import Section from '@greenberry/salal/components/Section';
import MainWrapper from '@greenberry/salal/components/MainWrapper';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Wysiwyg from '../components/Wysiwyg';

const Privacy = () => {
  const { datoCmsPrivacy: copy } = useStaticQuery(graphql`
    query getPrivacy {
      datoCmsPrivacy {
        title
        body
        seoMetaTags {
          tags
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title={prop('title', copy)}
        tags={path(['seoMetaTags', 'tags'], copy)}
      />
      <HeroV4
        content={
          <Heading component="h1" size="xxxl" weight="semibold">
            {prop('title', copy)}
          </Heading>
        }
      />
      <MainWrapper>
        <Section size="1">
          <Wysiwyg body={prop('body', copy)} />
        </Section>
      </MainWrapper>
    </Layout>
  );
};

export default Privacy;
