import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '@greenberry/salal/theme';

const Wrapper = styled.div`
  p {
    margin: ${theme.spacing('m')} 0;
  }

  ul,
  ol {
    padding: 0 ${theme.spacing('m')};
  }
`;

const Wysiwyg = ({ body }) => (
  <Wrapper dangerouslySetInnerHTML={{ __html: body }} />
);

Wysiwyg.propTypes = {
  body: PropTypes.string.isRequired,
};

export default Wysiwyg;
